import axios from 'axios';
import { getErrorObject } from 'utils/apiUtils';
import { setErrors } from './errorHandler';
import { getAppLookupBody, getFeedbackLookupBody, getOrgLookupBody, getRemediationGuidanceReasonsBody, getStageOwnerLookupBody } from 'utils/lookupUtils';

export const ASSESSMENT_REQUEST_TYPE_LOADING = '@@lookup/ASSESSMENT_REQUEST_TYPE_LOADING';
export const ASSESSMENT_REQUEST_TYPE_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_TYPE_SUCCESS';
export const ASSESSMENT_REQUEST_TYPE_FAILURE = '@@lookup/ASSESSMENT_REQUEST_TYPE_FAILURE';

export const getAssessmentRequestType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookUp/e=AssessmentRequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const GET_ASSESSMENT_LABEL_LIST_LOADING = '@@lookup/GET_ASSESSMENT_LABEL_LIST_LOADING';
export const GET_ASSESSMENT_LABEL_LIST_SUCCESS = '@@lookup/GET_ASSESSMENT_LABEL_LIST_SUCCESS';
export const GET_ASSESSMENT_LABEL_LIST_FAILURE = '@@lookup/GET_ASSESSMENT_LABEL_LIST_FAILURE';

export const getAssessmentLabelList = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_LABEL_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=EditAssessmentOption`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_LABEL_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_LABEL_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_LABEL_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_CATEGORY_LOADING = '@@lookup/ASSESSMENT_REQUEST_CATEGORY_LOADING';
export const ASSESSMENT_REQUEST_CATEGORY_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_CATEGORY_SUCCESS';
export const ASSESSMENT_REQUEST_CATEGORY_FAILURE = '@@lookup/ASSESSMENT_REQUEST_CATEGORY_FAILURE';

export const getAssessmentRequestCategory = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_CATEGORY_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentRequestCategory`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_CATEGORY_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_CATEGORY_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_CATEGORY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING = '@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING';
export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS';
export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE = '@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE';

export const getAssessmentRequestProductCategory = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentRequestProductCategory`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING = '@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING';
export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS';
export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE = '@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE';

export const getAssessmentRequestScopingInformation = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookUp/e=AssessmentRequestScopingInformation`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING = '@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING';
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS';
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE = '@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE';

export const getAssessmentRequestSecurityControlInformation = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentRequestSecurityControlInformation`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_YES_NO_LIST_LOADING = '@@lookup/GET_YES_NO_LIST_LOADING';
export const GET_YES_NO_LIST_SUCCESS = '@@lookup/GET_YES_NO_LIST_SUCCESS';
export const GET_YES_NO_LIST_FAILURE = '@@lookup/GET_YES_NO_LIST_FAILURE';

export const getYesNoList = () => async (dispatch) => {
  dispatch({
    type: GET_YES_NO_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=YesNo`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_YES_NO_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_YES_NO_LIST_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_YES_NO_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_TICKET_TYPE_LOADING = '@@lookup/ASSESSMENT_TICKET_TYPE_LOADING';
export const ASSESSMENT_TICKET_TYPE_SUCCESS = '@@lookup/ASSESSMENT_TICKET_TYPE_SUCCESS';
export const ASSESSMENT_TICKET_TYPE_FAILURE = '@@lookup/ASSESSMENT_TICKET_TYPE_FAILURE';

export const getAssessmentTicketType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_TICKET_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentTicketType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_TICKET_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_TICKET_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_TICKET_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_UPDATE_TYPE_LOADING = '@@lookup/ASSESSMENT_UPDATE_TYPE_LOADING';
export const ASSESSMENT_UPDATE_TYPE_SUCCESS = '@@lookup/ASSESSMENT_UPDATE_TYPE_SUCCESS';
export const ASSESSMENT_UPDATE_TYPE_FAILURE = '@@lookup/ASSESSMENT_UPDATE_TYPE_FAILURE';

export const getAssessmentUpdateType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_UPDATE_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentUpdate`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_UPDATE_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_UPDATE_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_UPDATE_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_FILE_TYPE_LOADING = '@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_LOADING';
export const ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS = '@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS';
export const ASSESSMENT_REQUEST_FILE_TYPE_FAILURE = '@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_FAILURE';

export const getAssessmentRequestFileType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_FILE_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=ClientFileType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_FILE_TYPE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_FILE_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING = '@@lookup/GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING';
export const GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS = '@@lookup/GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS';
export const GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE = '@@lookup/GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE';

export const getSupportTicketRequestType = () => async (dispatch) => {
  dispatch({
    type: GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=RequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_RESPONSE_CATEGORY_LIST_LOADING = '@@lookup/GET_RESPONSE_CATEGORY_LIST_LOADING';
export const GET_RESPONSE_CATEGORY_LIST_SUCCESS = '@@lookup/GET_RESPONSE_CATEGORY_LIST_SUCCESS';
export const GET_RESPONSE_CATEGORY_LIST_FAILURE = '@@lookup/GET_RESPONSE_CATEGORY_LIST_FAILURE';

export const getResponseCategoryList = () => async (dispatch) => {
  dispatch({ type: GET_RESPONSE_CATEGORY_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=YesNo`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_RESPONSE_CATEGORY_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_RESPONSE_CATEGORY_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_RESPONSE_CATEGORY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_DOMAIN_CAPABILITIES_LIST_LOADING = '@@lookup/GET_DOMAIN_CAPABILITIES_LIST_LOADING';
export const GET_DOMAIN_CAPABILITIES_LIST_SUCCESS = '@@lookup/GET_DOMAIN_CAPABILITIES_LIST_SUCCESS';
export const GET_DOMAIN_CAPABILITIES_LIST_FAILURE = '@@lookup/GET_DOMAIN_CAPABILITIES_LIST_FAILURE';

export const getDomainCapabilitiesList = () => async (dispatch) => {
  dispatch({ type: GET_DOMAIN_CAPABILITIES_LIST_LOADING });
  try {
    const body = getOrgLookupBody();
    const response = await axios.post(`/app/lookup/org/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_DOMAIN_CAPABILITIES_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_DOMAIN_CAPABILITIES_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOMAIN_CAPABILITIES_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING = '@@lookup/GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING';
export const GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS = '@@lookup/GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS';
export const GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE = '@@lookup/GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE';

export const getAssessmentStageOwnerList = () => async (dispatch) => {
  dispatch({ type: GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING });
  try {
    const body = getStageOwnerLookupBody();
    const response = await axios.post(`/legacy/lookup/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_VENDOR_RELATIONSHIP_STATUS_LOADING = '@@lookup/GET_VENDOR_RELATIONSHIP_STATUS_LOADING';
export const GET_VENDOR_RELATIONSHIP_STATUS_SUCCESS = '@@lookup/GET_VENDOR_RELATIONSHIP_STATUS_SUCCESS';
export const GET_VENDOR_RELATIONSHIP_STATUS_FAILURE = '@@lookup/GET_VENDOR_RELATIONSHIP_STATUS_FAILURE';

export const getVendorRelationShipStatus = () => async (dispatch) => {
  dispatch({ type: GET_VENDOR_RELATIONSHIP_STATUS_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=CustomerVendorRelationshipStatus`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_VENDOR_RELATIONSHIP_STATUS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_VENDOR_RELATIONSHIP_STATUS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VENDOR_RELATIONSHIP_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_VENDOR_RELATIONSHIP_TPIR_LOADING = '@@lookup/GET_VENDOR_RELATIONSHIP_TPIR_LOADING';
export const GET_VENDOR_RELATIONSHIP_TPIR_SUCCESS = '@@lookup/GET_VENDOR_RELATIONSHIP_TPIR_SUCCESS';
export const GET_VENDOR_RELATIONSHIP_TPIR_FAILURE = '@@lookup/GET_VENDOR_RELATIONSHIP_TPIR_FAILURE';

export const getVendorRelationShipTPIRStatus = () => async (dispatch) => {
  dispatch({ type: GET_VENDOR_RELATIONSHIP_TPIR_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=VendorTPIR`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_VENDOR_RELATIONSHIP_TPIR_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_VENDOR_RELATIONSHIP_TPIR_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VENDOR_RELATIONSHIP_TPIR_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const REMEDIATION_GUIDANCE_REASONS_LOADING = '@@lookup/REMEDIATION_GUIDANCE_REASONS_LOADING';
export const REMEDIATION_GUIDANCE_REASONS_SUCCESS = '@@lookup/REMEDIATION_GUIDANCE_REASONS_SUCCESS';
export const REMEDIATION_GUIDANCE_REASONS_FAILURE = '@@lookup/REMEDIATION_GUIDANCE_REASONS_FAILURE';

export const getRemediationGuidanceReasons = () => async (dispatch) => {
  dispatch({
    type: REMEDIATION_GUIDANCE_REASONS_LOADING,
  });

  const body = getRemediationGuidanceReasonsBody();
  try {
    const res = await axios.post(`/app/lookup/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: REMEDIATION_GUIDANCE_REASONS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: REMEDIATION_GUIDANCE_REASONS_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REMEDIATION_GUIDANCE_REASONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_FEEDBACK_RATING_LOADING = '@@lookup/GET_FEEDBACK_RATING_LOADING';
export const GET_FEEDBACK_RATING_SUCCESS = '@@lookup/GET_FEEDBACK_RATING_SUCCESS';
export const GET_FEEDBACK_RATING_FAILURE = '@@lookup/GET_FEEDBACK_RATING_FAILURE';

export const getFeedbackRatingList = () => async (dispatch) => {
  dispatch({ type: GET_FEEDBACK_RATING_LOADING });
  try {
    const body = getFeedbackLookupBody();
    const response = await axios.post(`/app/lookup/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_FEEDBACK_RATING_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_FEEDBACK_RATING_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_FEEDBACK_RATING_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_VALIDATION_PREFERENCES_LOADING = '@@lookup/GET_VALIDATION_PREFERENCES_LOADING';
export const GET_VALIDATION_PREFERENCES_SUCCESS = '@@lookup/GET_VALIDATION_PREFERENCES_SUCCESS';
export const GET_VALIDATION_PREFERENCES_FAILURE = '@@lookup/GET_VALIDATION_PREFERENCES_FAILURE';

export const getValidationPreferences = () => async (dispatch) => {
  dispatch({
    type: GET_VALIDATION_PREFERENCES_LOADING,
  });
  const body = getAppLookupBody('ValidationPreferences');
  try {
    const res = await axios.post(`/app/lookup/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_VALIDATION_PREFERENCES_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_VALIDATION_PREFERENCES_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VALIDATION_PREFERENCES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CORL_CLEARED_STATUS_LOADING = '@@lookup/GET_CORL_CLEARED_STATUS_LOADING';
export const GET_CORL_CLEARED_STATUS_SUCCESS = '@@lookup/GET_CORL_CLEARED_STATUS_SUCCESS';
export const GET_CORL_CLEARED_STATUS_FAILURE = '@@lookup/GET_CORL_CLEARED_STATUS_FAILURE';

export const getCorlClearedStatusList = () => async (dispatch) => {
  dispatch({
    type: GET_CORL_CLEARED_STATUS_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CorlClearedStatus`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CORL_CLEARED_STATUS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CORL_CLEARED_STATUS_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CORL_CLEARED_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_ASSESSMENT_VRAS_REQUST_LOADING = '@@lookup/GET_ASSESSMENT_VRAS_REQUST_LOADING';
export const GET_ASSESSMENT_VRAS_REQUST_SUCCESS = '@@lookup/GET_ASSESSMENT_VRAS_REQUST_SUCCESS';
export const GET_ASSESSMENT_VRAS_REQUST_FAILURE = '@@lookup/GET_ASSESSMENT_VRAS_REQUST_FAILURE';

export const getAssessmentVRASRequestType = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_VRAS_REQUST_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentVRASRequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_VRAS_REQUST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_VRAS_REQUST_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_VRAS_REQUST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_DOMAIN_LIST_LOADING = '@@lookup/GET_DOMAIN_LIST_LOADING';
export const GET_DOMAIN_LIST_SUCCESS = '@@lookup/GET_DOMAIN_LIST_SUCCESS';
export const GET_DOMAIN_LIST_FAILURE = '@@lookup/GET_DOMAIN_LIST_FAILURE';

export const getDomainList = (clientOrgId) => async (dispatch) => {
  dispatch({ type: GET_DOMAIN_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/org/e=Domain?clientOrgId=${clientOrgId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_DOMAIN_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_DOMAIN_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOMAIN_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CAPABILITY_GROUP_LIST_LOADING = '@@lookup/GET_CAPABILITY_GROUP_LIST_LOADING';
export const GET_CAPABILITY_GROUP_LIST_SUCCESS = '@@lookup/GET_CAPABILITY_GROUP_LIST_SUCCESS';
export const GET_CAPABILITY_GROUP_LIST_FAILURE = '@@lookup/GET_CAPABILITY_GROUP_LIST_FAILURE';

export const getCapabilityGroupList = (clientOrgId) => async (dispatch) => {
  dispatch({ type: GET_CAPABILITY_GROUP_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/org/e=CapabilitiesGroup?clientOrgId=${clientOrgId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_CAPABILITY_GROUP_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_CAPABILITY_GROUP_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CAPABILITY_GROUP_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_LOADING = '@@lookup/GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_LOADING';
export const GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_SUCCESS = '@@lookup/GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_SUCCESS';
export const GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE = '@@lookup/GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE';

export const portalRedirectionPreference = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=Portal`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
