import {
  SEARCH_ACTIVE_ASSESSMENT_LIST_FAILURE,
  SEARCH_ACTIVE_ASSESSMENT_LIST_LOADING,
  SEARCH_ACTIVE_ASSESSMENT_LIST_SUCCESS,
  SEARCH_COMPLETED_ASSESSMENT_LIST_FAILURE,
  SEARCH_COMPLETED_ASSESSMENT_LIST_LOADING,
  SEARCH_COMPLETED_ASSESSMENT_LIST_SUCCESS,
  SEARCH_REMEDIATION_ASSESSMENT_LIST_FAILURE,
  SEARCH_REMEDIATION_ASSESSMENT_LIST_LOADING,
  SEARCH_REMEDIATION_ASSESSMENT_LIST_SUCCESS,
  SEARCH_RESERVED_ASSESSMENT_LIST_LOADING,
  SEARCH_RESERVED_ASSESSMENT_LIST_SUCCESS,
  SEARCH_RESERVED_ASSESSMENT_LIST_FAILURE,
  POST_ASSESSMENT_TICKET_FAILURE,
  POST_ASSESSMENT_TICKET_LOADING,
  POST_ASSESSMENT_TICKET_SUCCESS,
  SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_SUCCESS,
  SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_FAILURE,
  SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_SUCCESS,
  SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FAILURE,
  SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_LOADING,
  SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_LOADING,
  SEARCH_REQUESTED_ASSESSMENTS_LOADING,
  SEARCH_REQUESTED_ASSESSMENTS_SUCCESS,
  SEARCH_REQUESTED_ASSESSMENTS_FAILURE,
  SEARCH_ASSESSMENT_LIST_FAILURE,
  SEARCH_ASSESSMENT_LIST_LOADING,
  SEARCH_ASSESSMENT_LIST_SUCCESS,
  RESET_ACTIVE_ASSESSMENTS,
  RESET_REMEDIATION_ASSESSMENTS,
  RESET_COMPLETED_ASSESSMENTS,
  SET_ASSESSMENT_TAB_VALUE,
  SEARCH_ASSESSMENT_CONFIG_LOADING,
  SEARCH_ASSESSMENT_CONFIG_SUCCESS,
  SEARCH_ASSESSMENT_CONFIG_FAILURE,
  SET_SELECTED_ASSESSMENT_DETAILS_CARD,
  GET_ASSESSMENT_NOTES_LOADING,
  GET_ASSESSMENT_NOTES_SUCCESS,
  GET_ASSESSMENT_NOTES_FAILURE,
  SEARCH_ASSESSMENT_BY_ID_SUCCESS,
  SEARCH_ASSESSMENT_BY_ID_FAILURE,
  SEARCH_ASSESSMENT_BY_ID_LOADING,
  GET_QUESTIONNAIRE_INFO_LOADING,
  GET_QUESTIONNAIRE_INFO_SUCCESS,
  GET_QUESTIONNAIRE_INFO_FAILURE,
  GET_ASSESSMENT_STATUS_HISTORY_SUCCESS,
  GET_ASSESSMENT_STATUS_HISTORY_FAILURE,
  GET_ASSESSMENT_STATUS_HISTORY_LOADING,
  RESET_QUESTIONAIRE_SUMMARY,
  SET_OPEN_ASSESSMENT_HELP_MODAL,
  SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET,
  SET_ASSESSMENT_PAGE_NO,
  SET_ASSESSMENT_ROW_PER_PAGE,
  SEARCH_ASSESSMENT_LIST_COUNT_LOADING,
  SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
  SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
  SEARCH_ASSESSMENT_QUEUE_LIST_FAILURE,
  SEARCH_ASSESSMENT_QUEUE_LIST_SUCCESS,
  SEARCH_ASSESSMENT_QUEUE_LIST_LOADING,
  POST_UPDATED_QUEUE_ORDER_LOADING,
  POST_UPDATED_QUEUE_ORDER_SUCCESS,
  POST_UPDATED_QUEUE_ORDER_FAILURE,
  SET_QUEUED_ASSESSMENT_PAGE_NO,
  SET_QUEUED_ASSESSMENT_ROW_PER_PAGE,
  SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_SUCCESS,
  SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_LOADING,
  SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_FAILURE,
  SEARCH_ASSESSMENT_QUEUE_FULL_LIST_LOADING,
  SEARCH_ASSESSMENT_QUEUE_FULL_LIST_SUCCESS,
  SEARCH_ASSESSMENT_QUEUE_FULL_LIST_FAILURE,
  CLEAR_QUEUED_ASSESSMENT_FULL_LIST,
  SET_ASSESSMENT_NOTES,
  SEARCH_ASSESSMENT_LOADING,
  SEARCH_ASSESSMENT_SUCCESS,
  SEARCH_ASSESSMENT_FAILURE,
  SEARCH_ASSESSMENT_DETAILS_BY_STATUS_LOADING,
  SEARCH_ASSESSMENT_DETAILS_BY_STATUS_SUCCESS,
  SEARCH_ASSESSMENT_DETAILS_BY_STATUS_FAILURE,
  SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FOR_AUDIT,
  SET_SELECTED_ASSESSMENT_ID,
  GET_SELECTED_ASSESSMENT_ID,
  CLEAR_SELECTED_ASSESSMENT_ID,
  SET_ASSESSMENT_OVERVIEW_DATA,
  ADDITIONAL_NOTES_SEARCH_LOADING,
  ADDITIONAL_NOTES_SEARCH_SUCCESS,
  ADDITIONAL_NOTES_SEARCH_FAILURE,
  SET_SELECTED_QUESTIONNAIRE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE,
  SEARCH_QUESTIONNAIRE_PROGRESS_LOADING,
  SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS,
  SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE,
  GET_ASSESSMENT_FILE_UPLOADED_SUCCESS,
  GET_ASSESSMENT_FILE_UPLOADED_FAILURE,
  GET_ASSESSMENT_FILE_UPLOADED_LOADING,
  UPDATE_ASSESSMENT_OPTION_LOADING,
  UPDATE_ASSESSMENT_OPTION_SUCCESS,
  UPDATE_ASSESSMENT_OPTION_FAILURE
} from 'actions/assessmentsOverview';
import { ASSESSMENT_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { convertToLocalDateTime } from 'components/Common/TimeFunctions';
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_W_TIME_FORMAT, DEFAULT_ROWS_PER_PAGE, YES_VALUE } from 'constants/constants';
import { ASSESSMENT_QUEUE_ORDER_SUCCESSFULLY, ERROR_MESSAGE } from 'constants/errorMessage';
import { isEmpty, isNil } from 'lodash';
import { ASSESSMENT_OVERVIEW_TAB_OPTIONS, CORL_AUDITOR_VALIDATION, DEFAULT_ASSESSMENT_QUEUE_ROWS_PER_PAGE, QUEUED_FOR_AUDIT } from 'pages/AssessmentsOverview/constants';
import { toast } from 'react-toastify';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { getModifiedList } from 'utils/assessmentsOverviewUtils';


const initialState = {
  loading: false,
  loadingAssessment: false,
  loadingStatusHistory: false,
  remediationLoading: false,
  activeLoading: false,
  reservedLoading: false,
  totalActiveLoading: false,
  completedLoading: false,
  requestedAssessmentsLoading: false,
  assessmentListLoading: false,
  error: '',
  remediationAssessments: [],
  remediationAssessmentsCount: 0,
  activeAssessments: [],
  activeAssessmentsCount: 0,
  corlClearedActiveAssessmentsCount: 0,
  reservedAssessments: [],
  reservedAssessmentsCount: 0,
  totalActiveAssessments: [],
  completedAssessments: [],
  completedAssessmentsCount: 0,
  assessmentsList: [],
  assessmentsListCount: 0,
  assessmentPageNo: 0,
  assessmentRowsPerPage: DEFAULT_ROWS_PER_PAGE,
  assessmentTabValue: ASSESSMENT_OVERVIEW_TAB_OPTIONS.BOARD,
  requestedAssessmentsList: [],
  assessmentConfigurationStatuses: [],
  selectedAssessment: {},
  statusHistory: [],
  assessmentNotes: [],
  assessment: {},
  totalAssessmentInfo: {},
  totalQuestionnaireInfo: [],
  scopingQuestionnaireInfo: [],
  controlQuestionnaireInfo: [],
  supplementalQuestionnaireInfo: [],
  openAssessmentHelpModal: false,
  openAssessmentDetailsSidesheet: false,
  assessmentsQueueOrderNumber: {},
  assessmentsQueueList: [],
  queuedAssessmentPageNo: 0,
  queuedAssessmentRowsPerPage: DEFAULT_ASSESSMENT_QUEUE_ROWS_PER_PAGE,
  queuedAssessmentsListCount: 0,
  assessmentsQueueFullList: [],
  assessmentQueueFullListLoading: false,
  assessmentData: {},
  totalActiveAssessmentsForAuditCount: 0,
  selectedAssessmentId: null,
  portalNotes: [],
  selectedQuestionnaire: {},
  requirementList: [],
  assessmentQuestionnaireProgress: [],
  questionnaireProgress: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_REMEDIATION_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        remediationLoading: true,
      };
    }
    case SEARCH_ACTIVE_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        activeLoading: true,
      };
    }
    case SEARCH_RESERVED_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        reservedLoading: true,
      };
    }
    case SEARCH_COMPLETED_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        completedLoading: true,
      };
    }
    case POST_ASSESSMENT_TICKET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        assessmentListLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_DETAILS_BY_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_LOADING:
    case SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_LOADING: {
      return {
        ...state,
        totalActiveLoading: true,
      };
    }
    case GET_ASSESSMENT_NOTES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_QUESTIONNAIRE_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_REMEDIATION_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        remediationLoading: false,
        remediationAssessments: getModifiedList(payload?.Data?.Rows),
        remediationAssessmentsCount: payload?.Data?.Rows?.length > 0 ? payload?.Data?.Rows?.length : 0,
      };
    }

    case SEARCH_ACTIVE_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        activeLoading: false,
        activeAssessments: getModifiedList(payload?.Data?.Rows),
        totalActiveAssessments: getModifiedList(payload?.Data?.Rows),
        activeAssessmentsCount: payload?.Data?.Rows?.length > 0 ? payload?.Data?.Rows?.length : 0,
        corlClearedActiveAssessmentsCount: payload?.Data?.Rows?.length > 0 ? payload?.Data?.Rows?.length : 0,
      };
    }

    case SEARCH_RESERVED_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        reservedLoading: false,
        reservedAssessments: getModifiedList(payload?.Data?.Rows),
        reservedAssessmentsCount: payload?.Data?.Rows?.length > 0 ? payload?.Data?.Rows?.length : 0,
      };
    }

    case SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        totalActiveLoading: false,
        totalActiveAssessments: payload,
        corlClearedActiveAssessmentsCount: payload?.length > 0 ? payload?.length : 0,
      };
    }

    case SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        totalActiveLoading: false,
        totalActiveAssessments: payload,
        activeAssessmentsCount: payload?.length > 0 ? payload?.length : 0,
      };
    }
    case GET_ASSESSMENT_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentNotes: payload,
      };
    }
    case GET_QUESTIONNAIRE_INFO_SUCCESS: {
      let total = [], scoping = [], control = [], supplemental = [];
      payload.forEach((item) => {
        if (item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList.length > 0) {
          item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.forEach((element) => {
            if (element?.QuestionnaireTemplateType === 'scoping') {
              scoping.push(element);
            } else if (element?.QuestionnaireTemplateType === 'control' || element?.QuestionnaireTemplateType === 'Vendor Security Risk Assessment') {
              control.push(element);
            } else if (element?.QuestionnaireTemplateType === 'Supplemental') {
              supplemental.push(element);
            }
            total.push(element);
          });
        }
      });
      return {
        ...state,
        loading: false,
        totalQuestionnaireInfo: total,
        scopingQuestionnaireInfo: scoping,
        controlQuestionnaireInfo: control,
        supplementalQuestionnaireInfo: supplemental,
        totalAssessmentInfo: payload?.length > 0 ? payload[0] : {}
      };
    }
    case SEARCH_COMPLETED_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        completedLoading: false,
        completedAssessments: getModifiedList(
          payload?.Data?.Rows[0]?.CompletedAssessmentList,
          payload?.Data?.Rows[0]?.CountOfCompletedAssessment
        ),
        completedAssessmentsCount: payload?.Data?.Rows[0]?.CountOfCompletedAssessment,
      };
    }

    case SEARCH_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        assessmentListLoading: false,
        assessmentsList: getModifiedList(payload?.Data?.Rows),
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentsListCount: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0]?.CountOfAssessment,
      };
    }

    case SEARCH_REMEDIATION_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        remediationLoading: false,
        remediationAssessments: [],
        remediationAssessmentsCount: 0,
      };
    }

    case SEARCH_ACTIVE_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        activeLoading: false,
        activeAssessments: [],
        activeAssessmentsCount: 0,
        corlClearedActiveAssessmentsCount: 0,
      };
    }

    case SEARCH_RESERVED_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        reservedLoading: false,
        reservedAssessments: [],
        reservedAssessmentsCount: 0,
      };
    }

    case SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FAILURE:
    case SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        totalActiveLoading: false,
        totalActiveAssessments: [],
      };
    }
    case GET_ASSESSMENT_NOTES_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentNotes: [],
      };
    }
    case GET_QUESTIONNAIRE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        totalQuestionnaireInfo: [],
        scopingQuestionnaireInfo: [],
        controlQuestionnaireInfo: [],
        supplementalQuestionnaireInfo: [],
        totalAssessmentInfo: {}
      };
    }
    case SEARCH_COMPLETED_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        completedLoading: false,
        completedAssessments: [],
        completedAssessmentsCount: 0,
      };
    }

    case POST_ASSESSMENT_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        assessmentListLoading: false,
        assessmentsList: [],
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentsListCount: 0,
      };
    }

    case POST_ASSESSMENT_TICKET_SUCCESS: {
      toast.success(ASSESSMENT_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_REQUESTED_ASSESSMENTS_LOADING: {
      return {
        ...state,
        requestedAssessmentsLoading: true,
      };
    }

    case SEARCH_REQUESTED_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        requestedAssessmentsLoading: false,
        requestedAssessmentsList: payload?.Data?.Rows?.map((item) => {
          return {
            ...item,
            InsertTimestamp: !isNil(item.InsertTimestamp) && convertToLocalDateTime(item.InsertTimestamp, DATE_FORMAT),
          };
        }),
      };
    }

    case SEARCH_REQUESTED_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        requestedAssessmentsLoading: false,
        requestedAssessmentsList: [],
      };
    }

    case SEARCH_ASSESSMENT_QUEUE_LIST_LOADING: {
      return {
        ...state,
        assessmentQueueLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUEUE_LIST_SUCCESS: {
      return {
        ...state,
        assessmentQueueLoading: false,
        assessmentsQueueList: payload?.Data?.Rows?.length > 0 ? payload?.Data?.Rows?.map((item, index) => {
          return {
            ...item,
            Id: index + 1
          }
        }) : []
      };
    }

    case SEARCH_ASSESSMENT_QUEUE_LIST_FAILURE: {
      return {
        ...state,
        assessmentQueueLoading: false,
        assessmentsQueueList: [],
      };
    }

    case SEARCH_ASSESSMENT_QUEUE_FULL_LIST_LOADING: {
      return {
        ...state,
        assessmentQueueFullListLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUEUE_FULL_LIST_SUCCESS: {
      let orderNumber = {};
      payload?.Data?.Rows.forEach((obj, index) => {
        if (!isNil(obj.OrderNo)) {
          orderNumber[`OrderId${index}`] = obj.OrderNo
        } else {
          orderNumber[`OrderId${index}`] = index + 1
        }
      });
      return {
        ...state,
        assessmentQueueFullListLoading: true,
        assessmentsQueueFullList: payload?.Data?.Rows,
        assessmentsQueueOrderNumber: orderNumber
      };
    }

    case SEARCH_ASSESSMENT_QUEUE_FULL_LIST_FAILURE: {
      return {
        ...state,
        assessmentQueueFullListLoading: false,
        assessmentsQueueFullList: [],
        assessmentsQueueOrderNumber: {}
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_LOADING: {
      return {
        ...state,
        assessmentConfigLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_SUCCESS: {
      return {
        ...state,
        assessmentConfigLoading: false,
        assessmentConfigurationStatuses: getSelectOptionsList(payload?.Data?.Rows, 'AssessmentDisplayStatus', 'AssessmentDisplayStatus'),
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_FAILURE: {
      return {
        ...state,
        assessmentConfigLoading: false,
        assessmentConfigurationStatuses: [],
      };
    }

    case SET_ASSESSMENT_TAB_VALUE: {
      return {
        ...state,
        assessmentTabValue: payload || ASSESSMENT_OVERVIEW_TAB_OPTIONS.BOARD,
      };
    }

    case SET_SELECTED_ASSESSMENT_DETAILS_CARD: {
      return {
        ...state,
        selectedAssessment: payload,
      };
    }

    case RESET_ACTIVE_ASSESSMENTS: {
      return {
        ...state,
        activeAssessments: [],
        totalActiveAssessments: [],
        activeAssessmentsCount: 0,
        corlClearedActiveAssessmentsCount: 0,
      };
    }

    case RESET_REMEDIATION_ASSESSMENTS: {
      return {
        ...state,
        remediationAssessments: [],
        remediationAssessmentsCount: 0,
      };
    }

    case RESET_COMPLETED_ASSESSMENTS: {
      return {
        ...state,
        completedAssessments: [],
        completedAssessmentsCount: 0,
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_LOADING: {
      return {
        ...state,
        loadingAssessment: true,
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_SUCCESS: {
      const list = getModifiedList(payload?.Data?.Rows);
      return {
        ...state,
        loadingAssessment: false,
        assessment: list?.length > 0 && list[0],
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_FAILURE: {
      return {
        ...state,
        loadingAssessment: false,
        assessment: {},
      };
    }

    case GET_ASSESSMENT_STATUS_HISTORY_LOADING: {
      return {
        ...state,
        loadingStatusHistory: true,
      };
    }

    case GET_ASSESSMENT_STATUS_HISTORY_SUCCESS: {
      return {
        ...state,
        loadingStatusHistory: false,
        statusHistory: payload.map((it) => {
          return {
            ...it,
            CreatedDateConverted: convertToLocalDateTime(it?.CreatedDate, DATE_W_TIME_FORMAT),
          };
        })
      };
    }

    case GET_ASSESSMENT_STATUS_HISTORY_FAILURE: {
      return {
        ...state,
        loadingStatusHistory: false,
        statusHistory: []
      };
    }

    case RESET_QUESTIONAIRE_SUMMARY: {
      return {
        ...state,
        totalQuestionnaireInfo: [],
        scopingQuestionnaireInfo: [],
        controlQuestionnaireInfo: [],
        supplementalQuestionnaireInfo: [],
        totalAssessmentInfo: {}
      };
    }

    case SET_OPEN_ASSESSMENT_HELP_MODAL: {
      return {
        ...state,
        openAssessmentHelpModal: payload,
      };
    }

    case SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET: {
      return {
        ...state,
        openAssessmentDetailsSidesheet: payload,
      };
    }

    case SET_ASSESSMENT_PAGE_NO: {
      return {
        ...state,
        assessmentPageNo: payload,
      };
    }

    case SET_ASSESSMENT_ROW_PER_PAGE: {
      return {
        ...state,
        assessmentRowsPerPage: payload,
      };
    }
    case POST_UPDATED_QUEUE_ORDER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_UPDATED_QUEUE_ORDER_SUCCESS: {
      toast.success(ASSESSMENT_QUEUE_ORDER_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_UPDATED_QUEUE_ORDER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SET_QUEUED_ASSESSMENT_PAGE_NO: {
      return {
        ...state,
        queuedAssessmentPageNo: payload,
      };
    }
    case SET_QUEUED_ASSESSMENT_ROW_PER_PAGE: {
      return {
        ...state,
        queuedAssessmentRowsPerPage: payload,
      };
    }
    case SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        queuedAssessmentsListCount: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0]?.CountOfAssessment,
      };
    }
    case CLEAR_QUEUED_ASSESSMENT_FULL_LIST: {
      return {
        ...state,
        loading: false,
        assessmentsQueueFullList: [],
        assessmentsQueueOrderNumber: {}
      };
    }

    case SET_ASSESSMENT_NOTES: {
      return {
        ...state,
        assessmentNotes: payload,
      }
    }

    case SEARCH_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_SUCCESS: {
      const { status } = action;
      let selectedAssessmentData = payload?.Data?.Rows;
      if (!isNil(status)) {
        selectedAssessmentData =
          payload?.Data?.Rows?.length > 0 &&
          getModifiedList(!isEmpty(payload?.Data?.Rows[0]?.CompletedAssessmentList) ? payload?.Data?.Rows[0]?.CompletedAssessmentList : payload?.Data?.Rows);
      }
      return {
        ...state,
        loading: false,
        assessmentData: !isEmpty(selectedAssessmentData) ? selectedAssessmentData[0] : {},
      };
    }

    case SEARCH_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentData: {},
      };
    }

    case SEARCH_ASSESSMENT_DETAILS_BY_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedAssessment: payload
      }
    }

    case SEARCH_ASSESSMENT_DETAILS_BY_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        selectedAssessment: {}
      }
    }
    case SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FOR_AUDIT: {
      const assessmentForAudit = payload.filter(item => item.AssessmentStatusId === QUEUED_FOR_AUDIT || item.AssessmentStatusId === CORL_AUDITOR_VALIDATION);
      return {
        ...state,
        totalActiveAssessmentsForAuditCount: assessmentForAudit?.length > 0 ? assessmentForAudit?.length : 0,
      };
    }

    case SET_SELECTED_ASSESSMENT_ID: {
      window.localStorage.setItem('selectedAssessmentId', payload);
      return {
        ...state,
        selectedAssessmentId: payload,
      };
    }

    case GET_SELECTED_ASSESSMENT_ID: {
      return {
        ...state,
        selectedAssessmentId: window.localStorage.getItem('selectedAssessmentId'),
      };
    }

    case CLEAR_SELECTED_ASSESSMENT_ID: {
      window.localStorage.setItem('selectedAssessmentId', null);
      return {
        ...state,
        selectedAssessmentId: null,
      };
    }

    case SET_ASSESSMENT_OVERVIEW_DATA: {
      return {
        ...state,
        assessmentData: payload,
      };
    }

    case ADDITIONAL_NOTES_SEARCH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADDITIONAL_NOTES_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        portalNotes: payload.map((item) => {
          return {
            ...item,
            CreatedOnConverted: !isEmpty(item?.InsertTimestamp) ? convertToLocalDateTime(item?.InsertTimestamp, DATE_TIME_FORMAT) : null,
          };
        })
      };
    };
    case ADDITIONAL_NOTES_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        portalNotes: [],
      };
    };

    case SET_SELECTED_QUESTIONNAIRE: {
      return {
        ...state,
        selectedQuestionnaire: payload,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING: {
      return {
        ...state,
        requirementLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS: {
      return {
        ...state,
        requirementLoading: false,
        requirementList: payload,
        unansweredRequirements: Array(payload) ? payload.filter((item) => item.CountOfParentQuestionAnswers !== item.CountOfParentQuestions) : [],
        answeredRequirements: Array(payload) ? payload.filter((item) => item.CountOfParentQuestionAnswers === item.CountOfParentQuestions) : [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE: {
      return {
        ...state,
        requirementLoading: false,
        requirementList: [],
        unansweredRequirements: [],
        answeredRequirements: [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS: {

      let list = [];
      payload.forEach((obj) => {
        list.push({
          ...obj,
          RequirementName: obj?.RequirementGroupName,
          CountOfParentQuestionAnswers: obj?.CountOfRequirementGroupParentQuestionAnswers,
          CountOfParentQuestions: obj?.CountOfRequirementGroupParentQuestions,
          isRequirementGroup: YES_VALUE,
        });
      });

      return {
        ...state,
        loading: false,
        requirementList: list,
        unansweredRequirements: Array(list) ? list.filter((item) => item.CountOfRequirementGroupParentQuestionAnswers !== item.CountOfRequirementGroupParentQuestions) : [],
        answeredRequirements: Array(list) ? list.filter((item) => item.CountOfRequirementGroupParentQuestionAnswers === item.CountOfRequirementGroupParentQuestions) : [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        requirementList: [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentQuestionnaireProgress: payload,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentQuestionnaireProgress: [],
      };
    }

    case SEARCH_QUESTIONNAIRE_PROGRESS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireProgress: payload,
      };
    }

    case SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        questionnaireProgress: [],
      };
    }

    case GET_ASSESSMENT_FILE_UPLOADED_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ASSESSMENT_FILE_UPLOADED_SUCCESS: {
      return {
        ...state,
        loading: false,
        fileUploads: payload,
      };
    }

    case GET_ASSESSMENT_FILE_UPLOADED_FAILURE: {
      return {
        ...state,
        loading: false,
        fileUploads: payload,
      };
    }

    case UPDATE_ASSESSMENT_OPTION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_ASSESSMENT_OPTION_SUCCESS:
    case UPDATE_ASSESSMENT_OPTION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

