import {
  ASSESSMENT_REQUEST_CATEGORY_FAILURE,
  ASSESSMENT_REQUEST_CATEGORY_LOADING,
  ASSESSMENT_REQUEST_CATEGORY_SUCCESS,
  ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE,
  ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING,
  ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS,
  ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE,
  ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING,
  ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS,
  ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE,
  ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING,
  ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS,
  ASSESSMENT_REQUEST_TYPE_FAILURE,
  ASSESSMENT_REQUEST_TYPE_LOADING,
  ASSESSMENT_REQUEST_TYPE_SUCCESS,
  GET_YES_NO_LIST_FAILURE,
  GET_YES_NO_LIST_LOADING,
  GET_YES_NO_LIST_SUCCESS,
  ASSESSMENT_TICKET_TYPE_FAILURE,
  ASSESSMENT_TICKET_TYPE_LOADING,
  ASSESSMENT_TICKET_TYPE_SUCCESS,
  ASSESSMENT_UPDATE_TYPE_FAILURE,
  ASSESSMENT_UPDATE_TYPE_LOADING,
  ASSESSMENT_UPDATE_TYPE_SUCCESS,
  ASSESSMENT_REQUEST_FILE_TYPE_LOADING,
  ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS,
  ASSESSMENT_REQUEST_FILE_TYPE_FAILURE,
  GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING,
  GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS,
  GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE,
  GET_RESPONSE_CATEGORY_LIST_LOADING,
  GET_RESPONSE_CATEGORY_LIST_SUCCESS,
  GET_RESPONSE_CATEGORY_LIST_FAILURE,
  GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING,
  GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS,
  GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE,
  GET_DOMAIN_CAPABILITIES_LIST_LOADING,
  GET_DOMAIN_CAPABILITIES_LIST_FAILURE,
  GET_DOMAIN_CAPABILITIES_LIST_SUCCESS,
  GET_VENDOR_RELATIONSHIP_STATUS_LOADING,
  GET_VENDOR_RELATIONSHIP_STATUS_SUCCESS,
  GET_VENDOR_RELATIONSHIP_STATUS_FAILURE,
  GET_VENDOR_RELATIONSHIP_TPIR_LOADING,
  GET_VENDOR_RELATIONSHIP_TPIR_SUCCESS,
  GET_VENDOR_RELATIONSHIP_TPIR_FAILURE,
  REMEDIATION_GUIDANCE_REASONS_LOADING,
  REMEDIATION_GUIDANCE_REASONS_SUCCESS,
  REMEDIATION_GUIDANCE_REASONS_FAILURE,
  GET_FEEDBACK_RATING_LOADING,
  GET_FEEDBACK_RATING_SUCCESS,
  GET_FEEDBACK_RATING_FAILURE,
  GET_VALIDATION_PREFERENCES_LOADING,
  GET_VALIDATION_PREFERENCES_SUCCESS,
  GET_VALIDATION_PREFERENCES_FAILURE,
  GET_CORL_CLEARED_STATUS_LOADING,
  GET_CORL_CLEARED_STATUS_SUCCESS,
  GET_CORL_CLEARED_STATUS_FAILURE,
  GET_ASSESSMENT_VRAS_REQUST_LOADING,
  GET_ASSESSMENT_VRAS_REQUST_SUCCESS,
  GET_ASSESSMENT_VRAS_REQUST_FAILURE,
  GET_DOMAIN_LIST_SUCCESS,
  GET_DOMAIN_LIST_FAILURE,
  GET_DOMAIN_LIST_LOADING,
  GET_CAPABILITY_GROUP_LIST_SUCCESS,
  GET_CAPABILITY_GROUP_LIST_LOADING,
  GET_CAPABILITY_GROUP_LIST_FAILURE,
  GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_LOADING,
  GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_SUCCESS,
  GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE,
  GET_ASSESSMENT_LABEL_LIST_LOADING,
  GET_ASSESSMENT_LABEL_LIST_SUCCESS,
  GET_ASSESSMENT_LABEL_LIST_FAILURE,
} from 'actions/lookup';
import { REGULAR_ASSESSMENT, STANDARD_ASSESSMENT, ASSESSMENT_ESCALATED_LABEL_VALUE, ASSESSMENT_EXPEDITE_LABEL_VALUE,ASSESSMENT_PAUSE_LABEL_VALUE} from 'pages/AssessmentsOverview/constants';
import { getValueLabelList } from 'utils/arrayUtils';

const initialState = {
  loading: false,
  assessmentType: [],
  assessmentTypeLabelList: [],
  assessmentLabelList: [],
  assessmentCategory: [],
  productType: [],
  scopingInformation: [],
  securityControlInformation: [],
  yesNoList: [],
  ticketType: [],
  assessmentUpdate: [],
  clientFileType: [],
  supportTicketRequestType: [],
  domain: [],
  capabilityGroup: [],
  assessmentStage: [],
  owner: [],
  domainLabelList: [],
  capabilityGroupLabelList: [],
  customerVendorRelationShipStatus: [],
  vendorTPIR: [],
  remediationGuidanceReasons: [],
  feedbackRating: [],
  feedbackIssueCategory: [],
  validationPreferences: [],
  corlClearedStatusList: [],
  assessmentVRASRequestType: [],
  portalRedirectionPreference: [],
};

export const lookUp = (state = initialState, action) => {
  const { type, payload } = action;

  const getAssessmentTypeValueLabelList = (list) => {
    return list.map((item) => {
      if (item.Label.toLowerCase() === REGULAR_ASSESSMENT) {
        return {
          label: STANDARD_ASSESSMENT,
          value: STANDARD_ASSESSMENT,
        };
      } else {
        return {
          label: item.Label,
          value: item.Label,
        };
      }
    });
  };

  switch (type) {
    case ASSESSMENT_REQUEST_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUEST_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentType: payload,
        assessmentTypeLabelList: getAssessmentTypeValueLabelList(payload),
      };
    }
    case ASSESSMENT_REQUEST_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_ASSESSMENT_LABEL_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ASSESSMENT_LABEL_LIST_SUCCESS: {
      const filteredLabelList = payload?.Data?.filter(
        (item) => item.Value === ASSESSMENT_EXPEDITE_LABEL_VALUE ||
        item.Value === ASSESSMENT_ESCALATED_LABEL_VALUE ||
        item.Value === ASSESSMENT_PAUSE_LABEL_VALUE
      );
      return {
        ...state,
        loading: false,
        assessmentLabelList: getValueLabelList(filteredLabelList ?? []),
      };
    }
    

    case GET_ASSESSMENT_LABEL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentLabelList: payload,
      };
    }
    case ASSESSMENT_REQUEST_CATEGORY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUEST_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentCategory: payload,
      };
    }
    case ASSESSMENT_REQUEST_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        productType: payload,
      };
    }
    case ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        scopingInformation: payload,
      };
    }
    case ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        securityControlInformation: payload,
      };
    }
    case ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_YES_NO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_YES_NO_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        yesNoList: payload,
      };
    }

    case GET_YES_NO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_TICKET_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_TICKET_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_TICKET_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        ticketType: payload,
      };
    }
    case ASSESSMENT_UPDATE_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_UPDATE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_UPDATE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentUpdate: getValueLabelList(payload),
      };
    }
    case ASSESSMENT_REQUEST_FILE_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUEST_FILE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        clientFileType: payload,
      };
    }
    case GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        supportTicketRequestType: payload,
      };
    }
    case GET_RESPONSE_CATEGORY_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_RESPONSE_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        responseCategoryType: payload?.Data,
      };
    }
    case GET_RESPONSE_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_DOMAIN_CAPABILITIES_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_DOMAIN_CAPABILITIES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        domain: getValueLabelList(payload?.Data?.Domain, 'label'),
        domainLabelList: getValueLabelList(payload?.Data?.Domain, 'label'),
        capabilityGroup: getValueLabelList(payload?.Data?.CapabilitiesGroup, 'label'),
        capabilityGroupLabelList: getValueLabelList(payload?.Data?.CapabilitiesGroup, 'label'),
      };
    }
    case GET_DOMAIN_CAPABILITIES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentStage: getValueLabelList(payload?.Data?.AssessmentStage),
        owner: getValueLabelList(payload?.Data?.Owner),
      };
    }
    case GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    
    case GET_VENDOR_RELATIONSHIP_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VENDOR_RELATIONSHIP_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerVendorRelationShipStatus: getValueLabelList(payload?.Data, 'label'),
      };
    }
    case GET_VENDOR_RELATIONSHIP_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_VENDOR_RELATIONSHIP_TPIR_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VENDOR_RELATIONSHIP_TPIR_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorTPIR: getValueLabelList(payload?.Data),
      };
    }
    case GET_VENDOR_RELATIONSHIP_TPIR_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case REMEDIATION_GUIDANCE_REASONS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case REMEDIATION_GUIDANCE_REASONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        remediationGuidanceReasons: getValueLabelList(payload?.Data?.RemediationGuidanceReason),
      };
    }
    case REMEDIATION_GUIDANCE_REASONS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_FEEDBACK_RATING_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_FEEDBACK_RATING_SUCCESS: {
      return {
        ...state,
        loading: false,
        feedbackRating: getValueLabelList(payload?.Data?.ESFeedbackRating),
        feedbackIssueCategory: getValueLabelList(payload?.Data?.ESFeedbackIssueCategory, 'label'),
      };
    }
    case GET_FEEDBACK_RATING_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_VALIDATION_PREFERENCES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VALIDATION_PREFERENCES_SUCCESS: {
      const validationPreferences = getValueLabelList(payload?.ValidationPreferences).slice(0, 2);
      return {
        ...state,
        loading: false,
        validationPreferences: validationPreferences,
      };
    }
    case GET_VALIDATION_PREFERENCES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_CORL_CLEARED_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CORL_CLEARED_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        corlClearedStatusList: getValueLabelList(payload),
      };
    }
    case GET_CORL_CLEARED_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        corlClearedStatusList: [],
      };
    }
    case GET_ASSESSMENT_VRAS_REQUST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ASSESSMENT_VRAS_REQUST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentVRASRequestType: getValueLabelList(payload),
      };
    }

    case GET_ASSESSMENT_VRAS_REQUST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_DOMAIN_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        domain: getValueLabelList(payload?.Data, 'label'),
        domainLabelList: getValueLabelList(payload?.Data, 'label'),
      };
    }

    case GET_DOMAIN_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_DOMAIN_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_CAPABILITY_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        capabilityGroup: getValueLabelList(payload?.Data, 'label'),
        capabilityGroupLabelList: getValueLabelList(payload?.Data, 'label'),
      };
    }

    case GET_CAPABILITY_GROUP_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CAPABILITY_GROUP_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        portalRedirectionPreference: getValueLabelList(payload),
      }
    }

    case GET_ASSESSMENT_REQUEST_REDIRECTION_PREFERENCE_FAILURE: {
      return {
        ...state,
        loading: false,
        portalRedirectionPreference: [],
      }
    }

    default:
      return state;
  }
};
