import axios from 'axios';
import { isEmpty, isNil, uniqBy } from 'lodash';
import { getErrorObject } from 'utils/apiUtils';
import {
  convertToValueLabel,
  getAssessmentRequestQuestionSearchBody,
  getAssessmentRequestReviewCommentsbody,
  getAssessmentRequestSearchBody,
  getCustomerVendorProductListSearchBody,
  getQuestionnaireTemplateSearchBody,
  getvendorOverviewSearchListSearchBody,
} from 'utils/assessmentRequestUtils';
import { setErrors } from './errorHandler';
import { NO_VENDOR_PRODUCTS_FOUND_OPTION } from 'pages/Assessment/AssessmentRequest/constants';
import { NO_DATA_FOUND } from 'constants/errorMessage';

export const SET_ASSESSMENT_REQUEST_STEP_SUCCESS = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_STEP_SUCCESS';
export const SET_ASSESSMENT_REQUEST_RESPONSE_QUESTIONS_SUCCESS = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_RESPONSE_QUESTIONS_SUCCESS';
export const SET_ASSESSMENT_REQUEST_SUCCESS = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_SUCCESS';
export const SET_ASSESSMENT_REQUEST_EDIT_SUCCESS = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_EDIT_SUCCESS';
export const SET_ASSESSMENT_REQUEST_NAVIGATE_SUCCESS = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_NAVIGATE_SUCCESS';

export const setAssessmentRequestStep = (payload, responseQuestions, isSuccess, isEdit, isNavigate) => async (dispatch) => {
  dispatch({
    type: SET_ASSESSMENT_REQUEST_STEP_SUCCESS,
    payload: payload,
  });
  if (!isEmpty(responseQuestions)) {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_RESPONSE_QUESTIONS_SUCCESS,
      payload: responseQuestions,
    });
  } else if (!isNil(isSuccess)) {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_SUCCESS,
      payload: isSuccess,
    });
  } else if (!isNil(isEdit)) {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_EDIT_SUCCESS,
      payload: isEdit,
    });
  } else if (!isNil(isNavigate)) {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_NAVIGATE_SUCCESS,
      payload: isNavigate,
    });
  }
};

export const POST_ASSESSMENT_REQUEST_LOADING = '@@assessmentRequest/POST_ASSESSMENT_REQUEST_LOADING';
export const POST_ASSESSMENT_REQUEST_SUCCESS = '@@assessmentRequest/POST_ASSESSMENT_REQUEST_SUCCESS';
export const POST_ASSESSMENT_REQUEST_FAILURE = '@@assessmentRequest/POST_ASSESSMENT_REQUEST_FAILURE';

export const addAssessmentRequest = (formData, responseQuestions) => async (dispatch) => {
  dispatch({
    type: POST_ASSESSMENT_REQUEST_LOADING,
  });

  let newDescriptionList = [];
  let newFileTypeList = [];

  formData?.AssessmentRequestUploadedFileList.forEach((assessmentFile) => {
    newDescriptionList.push(assessmentFile.description);
    newFileTypeList.push(assessmentFile.fileType);
  });

  let tempFormData = {
    ...formData,
    AssessmentRequestQuestionResponseList: responseQuestions,
    UploadedFileDescriptionList: newDescriptionList,
    UploadedFileTypeList: newFileTypeList,
    BusinessDomain: formData?.BusinessDomain ? formData?.BusinessDomain?.toString() : null,
    BusinessCapabilityGroup: formData?.BusinessCapabilityGroup ? formData?.BusinessCapabilityGroup?.toString() : null,
  };

  delete tempFormData['AssessmentRequestUploadedFileList'];

  let body = new FormData();
  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });
  formData?.AssessmentRequestUploadedFileList.forEach((assessmentFile) => {
    body.append(`UploadedFileList`, assessmentFile.file);
  });

  try {
    const assessmentRes = await axios.post(`/client/assessmentrequest`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    if (assessmentRes?.data?.ErrorCount > 0) {
      dispatch(setErrors(assessmentRes?.data?.Errors));
      dispatch({
        type: POST_ASSESSMENT_REQUEST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_ASSESSMENT_REQUEST_SUCCESS,
        payload: assessmentRes?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_ASSESSMENT_REQUEST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const PUT_ASSESSMENT_REQUEST_LOADING = '@@assessmentRequest PUT_ASSESSMENT_REQUEST_LOADING';
export const PUT_ASSESSMENT_REQUEST_SUCCESS = '@@assessmentRequest PUT_ASSESSMENT_REQUEST_SUCCESS';
export const PUT_ASSESSMENT_REQUEST_FAILURE = '@@assessmentRequest PUT_ASSESSMENT_REQUEST_FAILURE';

export const updateAssessmentRequest = (formData, id) => async (dispatch) => {
  dispatch({
    type: PUT_ASSESSMENT_REQUEST_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    const res = await axios.put(`/client/assessmentrequest/${id}`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: PUT_ASSESSMENT_REQUEST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: PUT_ASSESSMENT_REQUEST_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: PUT_ASSESSMENT_REQUEST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_QUESTIONS_LIST_LOADING = '@@assessmentRequest/ASSESSMENT_REQUEST_QUESTIONS_LIST_LOADING';
export const ASSESSMENT_REQUEST_QUESTIONS_LIST_SUCCESS = '@@assessmentRequest/ASSESSMENT_REQUEST_QUESTIONS_LIST_SUCCESS';
export const ASSESSMENT_REQUEST_QUESTIONS_LIST_FAILURE = '@@assessmentRequest/ASSESSMENT_REQUEST_QUESTIONS_LIST_FAILURE';

export const getAssessmentRequestQuestionsList = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_QUESTIONS_LIST_LOADING,
  });

  const body = getAssessmentRequestQuestionSearchBody();
  try {
    const res = await axios.post(`/client/assessmentrequest/questions/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_QUESTIONS_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_QUESTIONS_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_QUESTIONS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_LOADING = '@@assessmentRequest/ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_LOADING';
export const ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_SUCCESS = '@@assessmentRequest/ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_SUCCESS';
export const ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_FAILURE = '@@assessmentRequest/ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_FAILURE';

export const getAssessmentRequestQuestionOptionsList = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_LOADING,
  });

  const body = getAssessmentRequestQuestionSearchBody();
  try {
    const res = await axios.post(`/client/assessmentrequest/questions/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_QUESTION_OPTIONS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_LOADING =
  '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_LOADING';
export const SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_SUCCESS =
  '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_FAILURE =
  '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_FAILURE';

export const getvendorOverviewSearchList = (formData) => async (dispatch) => {
  dispatch({
    type: SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_LOADING,
  });

  const body = getvendorOverviewSearchListSearchBody(formData);
  try {
    const res = await axios.post(`/app/customer/vendor/fulltext/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_SUCCESS,
        payload: res?.data,
      });
      dispatch(setShowManualAddVendorBox(true));
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_VENDOR = '@@assessmentRequest/SET_SELECTED_VENDOR';

export const setSelectedVendorData =
  (data = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_VENDOR,
      payload: data,
    });
  };

export const SET_SELECTED_VENDOR_TOGGLE = '@@assessmentRequest/SET_SELECTED_VENDOR_TOGGLE';

export const setSelectedVendorToggle = (val) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_VENDOR_TOGGLE,
    payload: val,
  });
};

export const SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST';

export const setVendorOverviewSearchList =
  (data = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST,
      payload: data,
    });
  };

export const SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_COUNT = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_COUNT';

export const setVendorOverviewSearchListCount =
  (val = null) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_COUNT,
      payload: val,
    });
  };

export const SET_ASSESSMENT_REQUEST_MANUAL_ADD_VENDOR_BOX = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_MANUAL_ADD_VENDOR_BOX';

export const setShowManualAddVendorBox =
  (val = null) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_MANUAL_ADD_VENDOR_BOX,
      payload: val,
    });
  };

export const SET_RAW_CUSTOMER_VENDOR_PRODUCT_LIST = '@@assessmentRequest/SET_RAW_CUSTOMER_VENDOR_PRODUCT_LIST';

export const setRawCustomerVendorProductList =
  (val = []) =>
  async (dispatch) => {
    dispatch({
      type: SET_RAW_CUSTOMER_VENDOR_PRODUCT_LIST,
      payload: val,
    });
  };

export const SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING =
  '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING';
export const SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS =
  '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE =
  '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE';

export const getCustomerVendorProductList =
  (vendorOrgId, searchText = '') =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING,
    });

    const body = getCustomerVendorProductListSearchBody(vendorOrgId, searchText);
    try {
      const res = await axios.post(`/customer/vendor/product/fulltext/search`, body);
      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST';

export const setCustomerVendorProductList =
  (val = [NO_VENDOR_PRODUCTS_FOUND_OPTION]) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST,
      payload: val,
    });
  };

export const SEARCH_ASSESSMENT_REQUEST_LOADING = '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_LOADING';
export const SEARCH_ASSESSMENT_REQUEST_SUCCESS = '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_SUCCESS';
export const SEARCH_ASSESSMENT_REQUEST_FAILURE = '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_FAILURE';

export const searchAssessmentRequest = (id) => async (dispatch) => {
  dispatch({
    type: SEARCH_ASSESSMENT_REQUEST_LOADING,
  });
  const body = getAssessmentRequestSearchBody(id);
  try {
    const res = await axios.post(`/serviceprovider/assessmentrequest/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_ASSESSMENT_REQUEST_FAILURE,
        payload: {},
      });
    } else {
      if (res?.data?.Data?.Rows?.length > 0) {
        const assessmentRequest = res?.data?.Data?.Rows[0];
        dispatch({
          type: SEARCH_ASSESSMENT_REQUEST_SUCCESS,
          payload: assessmentRequest,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_REQUEST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_ASSESSMENT_REQUEST = '@@assessmentRequests/SET_ASSESSMENT_REQUEST';

export const setAssessmentRequest =
  (val = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_REQUEST,
      payload: val,
    });
  };

export const SEARCH_COMMENTS_LOADING = '@assessmentRequest/SEARCH_COMMENTS_LOADING';
export const SEARCH_COMMENTS_SUCCESS = '@assessmentRequest/SEARCH_COMMENTS_SUCCESS';
export const SEARCH_COMMENTS_FAILURE = '@assessmentRequest/SEARCH_COMMENTS_FAILURE';

export const searchAssessmentRequestReviewComments = (id) => async (dispatch) => {
  dispatch({
    type: SEARCH_COMMENTS_LOADING,
  });
  const body = getAssessmentRequestReviewCommentsbody(id);
  try {
    const res = await axios.post(`serviceprovider/assessmentrequest/${id}/reviewcomment/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_COMMENTS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_COMMENTS_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_COMMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CLEAR_COMMENTS = '@assessmentRequest/CLEAR_COMMENTS';

export const clearComments = () => async (dispatch) => {
  dispatch({
    type: CLEAR_COMMENTS,
  });
};

export const SEARCH_DEFAULT_QT_REQUEST = '@assessmentRequests/SEARCH_DEFAULT_QT_REQUEST';
export const SEARCH_DEFAULT_QT_SUCCESS = '@assessmentRequests/SEARCH_DEFAULT_QT_SUCCESS';
export const SEARCH_DEFAULT_QT_FAILIURE = '@assessmentRequests/SEARCH_DEFAULT_QT_FAILIURE';

export const searchQuestionnaireTemplates = (questionnaireType = null, searchValue = null, page = 0, callback = null) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SEARCH_DEFAULT_QT_REQUEST,
    });

    const state = getState()?.assessmentRequests;
    const body = getQuestionnaireTemplateSearchBody(questionnaireType, searchValue, page);

    try {
      const res = await axios.post('/questionnairetemplate/search', body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);

        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_DEFAULT_QT_FAILIURE,
        });
      } else {
        const response = convertToValueLabel(res?.data?.Data?.Rows);

        let payload;
        let list = state?.questionnaireTemplateList || [];

        if (!isNil(searchValue)) {
          payload = {
            questionnaireTemplateList: uniqBy([...list, ...response], 'value'),
          };
        } else {
          payload = {
            questionnaireTemplateList: uniqBy([...list, ...response], 'value'),
            pageNo: page,
          };
        }

        dispatch({
          type: SEARCH_DEFAULT_QT_SUCCESS,
          payload,
        });

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch({
        type: SEARCH_DEFAULT_QT_FAILIURE,
      });
    }
  };
};

export const SET_QUESTIONNAIRE_TEMPLATES = '@assessmentRequests/SET_QUESTIONNAIRE_TEMPLATES';

export const setQuestionnaireTemplates = () => async (dispatch) => {
  dispatch({
    type: SET_QUESTIONNAIRE_TEMPLATES,
  });
};
