export const getOrgLookupBody = () => {
  return {
    intent: '',
    fields: ['Domain', 'CapabilitiesGroup'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getStageOwnerLookupBody = () => {
  return {
    intent: '',
    fields: ['AssessmentStage', 'Owner'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
export const getRemediationGuidanceReasonsBody = () => {
  return {
    intent: '',
    fields: ['RemediationGuidanceReason'],
    Criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getFeedbackLookupBody = () => {
  return {
    intent: '',
    fields: ['ESFeedbackRating', 'ESFeedbackIssueCategory'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAppLookupBody = (lookupData = "") => {
  const fields = [];
  fields.push(lookupData)
  return{
      "intent": "",
      "fields": fields,
      "Criterion": [],
      "sort": [],
      "page": 0,
      "pageSize": 0
  }
}