import { restrictSpaceAtStart, VALIDATIONS } from 'constants/validations';
import { isEmpty } from 'lodash';
import { NO_VENDOR_PRODUCTS_FOUND_OPTION } from './constants';

export const required = (v) => (v ? undefined : '* Required');

const email = (value) => (value && !VALIDATIONS.emailValidation.value.test(value) ? VALIDATIONS.emailValidation.error : undefined);
const alphabets = (value) => (value && VALIDATIONS.nameValidation.value.test(value) ? VALIDATIONS.nameValidation.error : undefined);
const restrictNumber = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(VALIDATIONS.onlyAlphabetValidation.value, '');
};
const domainValidate = (v) => !isEmpty(v) && (VALIDATIONS.noSubDomainValidation?.value.test(v) ? undefined : VALIDATIONS.noSubDomainValidation?.error);

const titleValidation = (value) => !isEmpty(value) && (VALIDATIONS.titleValidation.value.test(value) ? undefined : VALIDATIONS.titleValidation.error);

const phoneExtensionLength = (max) => (value) => value && value.length > max ? `Must be less than or equal to ${max} numbers` : undefined;

export const requestAssessmentCategoryFields = [
  {
    name: 'AssessmentRequestCategoryId',
    label: 'Is this a Regular Assessment or a Pre-Assessment?',
    tstype: 'togglebutton',
    validate: [required],
    required: true,
  },
];

export const requestAssessmentTypeFields = [
  {
    name: 'AssessmentRequestTypeId',
    label: 'Is this a CORLcleared Assessment?',
    tstype: 'togglebutton',
    validate: [required],
    required: true,
  },
];

export const requestAssessmentValidationFields = [
  {
    name: 'IsAssessmentValidated',
    label: 'What type of validation is this Assessment?',
    tstype: 'togglebutton',
    validate: [required],
    required: true,
  },
];

export const requestAssessmentCategoryReviewFields = [
  {
    name: 'AssessmentRequestCategoryIdReview',
    label: 'Is this a Regular Assessment or a Pre-Assessment?',
    tstype: 'togglebutton',
    validate: [required],
    required: true,
  },
];

export const requestAssessmentTypeReviewFields = [
  {
    name: 'AssessmentRequestTypeIdReview',
    label: 'Is this a CORLcleared Assessment?',
    tstype: 'togglebutton',
    validate: [required],
    required: true,
  },
];

export const requestAssessmentValidationReviewFields = [
  {
    name: 'IsAssessmentValidatedReview',
    label: 'What type of validation is this Assessment?',
    tstype: 'togglebutton',
  },
];

export const vendorOverviewSearchFields = [
  {
    label: 'Vendor Name',
    placeholder: 'Vendor Name',
    name: 'VendorName',
    tstype: 'text',
    variant: 'standard',
    maxLength: 512,
    required: true,
    validate: [required],
    normalize: restrictSpaceAtStart,
  },
  {
    label: 'Vendor Company URL',
    placeholder: 'Vendor Company URL',
    name: 'WebsiteUrl',
    tstype: 'text',
    variant: 'standard',
    required: true,
    validate: [required, domainValidate],
    maxLength: 1024,
  },
];

export const vendorOverviewCountryAndStateFields = [
  {
    label: 'Vendor Headquarters Country',
    placeholder: 'Vendor Headquarters Country',
    name: 'VendorHeadquarterCountry',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
  },
  {
    label: 'Vendor State',
    placeholder: 'Vendor State',
    name: 'VendorState',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
  },
];

export const vendorOverviewFields = [
  {
    label: 'Vendor Name',
    placeholder: 'Vendor Name',
    name: 'VendorOrgName',
    tstype: 'text',
    variant: 'standard',
    maxLength: 512,
    required: true,
    validate: [required],
    normalize: restrictSpaceAtStart,
  },
  {
    label: 'Vendor Company URL',
    placeholder: 'Vendor Company URL',
    name: 'VendorUrl',
    tstype: 'text',
    variant: 'standard',
    required: true,
    validate: [required, domainValidate],
    maxLength: 1024,
  },
  {
    label: 'Vendor Headquarters Country',
    placeholder: 'Vendor Headquarters Country',
    name: 'VendorHeadquarterCountry',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
  },
  {
    label: 'Vendor State',
    placeholder: 'Vendor State',
    name: 'VendorState',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
  },
];

export const vendorContactFields = [
  {
    label: 'First Name',
    placeholder: 'First Name',
    name: 'VendorContactFirstName',
    tstype: 'text',
    variant: 'standard',
    maxLength: 64,
    required: true,
    validate: [required, alphabets],
    normalize: restrictNumber,
  },
  {
    label: 'Last Name',
    placeholder: 'Last Name',
    name: 'VendorContactLastName',
    tstype: 'text',
    variant: 'standard',
    maxLength: 128,
    required: true,
    validate: [required, alphabets],
    normalize: restrictNumber,
  },
  {
    label: 'Title',
    placeholder: 'Title',
    name: 'VendorContactTitle',
    tstype: 'text',
    maxLength: 526,
    validate: [titleValidation],
    variant: 'standard',
    normalize: restrictSpaceAtStart,
  },
  {
    label: "Country Code",
    name: "VendorContactCountryCode",
    tstype: "countryCode",
    variant: "standard",
  },
  {
    label: 'Phone Number',
    placeholder: 'Phone Number',
    name: 'MainVendorContactPhone',
    tstype: 'number',
    variant: 'standard',
    maxNumberLength: 10,
  },
  {
    label: "Phone Extension",
    placeholder: "Ext.",
    name: "VendorContactPhoneExtension",
    tstype: "number",
    variant: "standard",
    validate: [phoneExtensionLength(6)],
  },
  {
    label: 'Email',
    placeholder: 'Email',
    name: 'VendorContactEmail',
    tstype: 'text',
    variant: 'standard',
    maxLength: 320,
    required: true,
    validate: [required, email],
  },
];

export const productInformationVendorProductRequestFields = [
  {
    label: 'Is this request for a vendor product or service? *',
    placeholder: 'Is this request for a vendor product or service?',
    name: 'ProductCategoryId',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
    required: true,
    validate: [required],
  },
]

export const productInformationMedicalDeviceFields = [
  {
    label: 'Is the product a medical device? *',
    placeholder: 'Is the product a medical device?',
    name: 'IsProductMedicalDevice',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
    required: true,
    validate: [required],
  },
  {
    label: 'Is the product a medical device? *',
    placeholder: 'Is the product a medical device?',
    name: 'IsProductMedicalDeviceReview',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
    required: true,
    validate: [required],
  }
]

export const productInformationLocationFields = [
  {
    label: 'At what location (e.g., facility name, division name, service line name, location name) is this product/service being implemented?',
    placeholder: 'At what location (e.g., facility name, division name, service line name, location name) is this product/service being implemented?',
    name: 'ProductLocation',
    tstype: 'text',
    variant: 'standard',
  }
]

export const productInformationProductNameFields = [
  {
    label: 'Product or Service Name *',
    placeholder: 'Product or Service Name',
    noOptionsText: "No Vendor Products Found",
    name: 'Product',
    tstype: "autocompleteWithFreeSolo",
    variant: "standard",
    required: true,
    validate: [required],
    maxLength: 128,
    noOption: NO_VENDOR_PRODUCTS_FOUND_OPTION,
    showHelperText: false,
    imgSrc: process.env.REACT_APP_CORL_CLEARED_S3_URL,
  },
]

export const productInformationModelIdentifierFields = [
  {
    label: 'Model or Version Identifier',
    placeholder: 'Model or Version Identifier',
    name: 'ProductVersion',
    tstype: 'text',
    maxLength: 128,
    variant: 'standard',
  },
]

export const productInformationProductUrlFields = [
  {
    label: 'Product URL',
    placeholder: 'Product URL',
    name: 'ProductUrl',
    tstype: 'text',
    variant: 'standard',
    maxLength: 1024,
    validate: [domainValidate],
  },
]

export const productInformationDescriptionFields = [
  {
    label: 'Product or Service Description',
    placeholder: 'Product or Service Description',
    name: 'ProductDescription',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    minRows: 3,
    maxLength: 1024,
    showCharacterCount: true,
  },
];

export const internalIdentifiersFields = [
  {
    label: 'Sectors',
    placeholder: 'Sectors',
    name: 'Sectors',
    tstype: 'text',
    variant: 'standard',
  },
  {
    label: 'Internal Assessment ID',
    placeholder: 'Internal Assessment ID',
    name: 'InternalAssessmentId',
    tstype: 'text',
    variant: 'standard',
    maxLength: 50,
  },
  {
    label: 'Internal Client Vendor ID',
    placeholder: 'Internal Client Vendor ID',
    name: 'InternalClientVendorId',
    tstype: 'text',
    variant: 'standard',
    maxLength: 50,
  },
];
export const internalIdentifiersTwoSectionFields = [
  {
    label: 'Business Domain',
    placeholder: 'Business Domain',
    name: 'BusinessDomain',
    tstype: 'multipleselectdropdown',
    variant: 'standard',
    disableUnderline: true,
  },
  {
    label: 'Business Capability Group',
    placeholder: 'Business Capability Group',
    name: 'BusinessCapabilityGroup',
    tstype: 'multipleselectdropdown',
    variant: 'standard',
    disableUnderline: true,
  },
  {
    label: 'Client Provided Vendor Identifier',
    placeholder: 'Client Provided Vendor Identifier',
    name: 'VendorIdForCustomer',
    tstype: 'text',
    variant: 'standard',
    maxLength: 104,
    normalize: restrictSpaceAtStart,
  },
];

export const scopingInformationFields = [
  {
    name: 'ScopingInformationId',
    label: 'How will the scoping information be collected?',
    tstype: 'togglebutton',
    validate: [required],
    required: true,
  },
];

export const securityInformationEvidenceFields = [
  {
    name: 'SecurityControlInformationId',
    label: 'How will Security Control Information and Evidence be collected?',
    tstype: 'togglebutton',
    validate: [required],
    required: true,
  },
];
export const scopingInformationReviewFields = [
  {
    name: 'ScopingInformationIdReview',
    label: 'How will the scoping information be collected?',
    tstype: 'togglebutton',
  },
];

export const securityInformationEvidenceReviewFields = [
  {
    name: 'SecurityControlInformationIdReview',
    label: 'How will Security Control Information and Evidence be collected?',
    tstype: 'togglebutton',
  },
];

export const BusinessStakeHolderFields = [
  {
    label: 'Business Stakeholder First Name',
    placeholder: 'Business Stakeholder First Name',
    name: 'StakeholderContactFirstName',
    tstype: 'text',
    variant: 'standard',
    validate: [alphabets],
    normalize: restrictNumber,
  },
  {
    label: 'Business Stakeholder Last Name',
    placeholder: 'Business Stakeholder Last Name',
    name: 'StakeholderContactLastName',
    tstype: 'text',
    variant: 'standard',
    validate: [alphabets],
    normalize: restrictNumber,
  },
  {
    label: 'Business Stakeholder Department',
    placeholder: 'Business Stakeholder Department',
    name: 'StakeholderContactDepartment',
    tstype: 'text',
    variant: 'standard',
  },
  {
    label: 'Business Stakeholder Email',
    placeholder: 'Business Stakeholder Email',
    name: 'StakeholderContactEmail',
    tstype: 'text',
    variant: 'standard',
    validate: [email],
  },
  {
    label: 'Business Stakeholder Phone',
    placeholder: 'Business Stakeholder Phone',
    name: 'StakeholderContactPhone',
    tstype: 'number',
    variant: 'standard',
    maxNumberLength: 15,
  },
];

export const singleSelectFields = [
  {
    name: 'singleSelect',
    tstype: 'select',
    variant: 'standard',
    placeholder: 'Please select',
    disableUnderline: true,
  },
];

export const multiSelectFields = [
  {
    name: 'multiSelect',
    tstype: 'multipleselectdropdown',
    variant: 'standard',
    placeholder: 'Select all that apply',
    disableUnderline: true,
  },
];

export const assessmentHelpRequestField = [
  {
    label: 'Help Request *',
    placeholder: 'Ticket Type',
    name: 'TicketTypeId',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
    required: true,
    validate: [required],
  },
];

export const assessmentUpdateField = [
  {
    label: 'Assessment Update *',
    placeholder: 'Assessment Update',
    name: 'AssessmentUpdateId',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
    required: true,
    validate: [required],
  },
];

export const assessmentHelpAdditionalInformationField = [
  {
    label: 'Additional Information',
    placeholder: 'Additional Information',
    name: 'Description',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    minRows: 3,
    maxLength: 2048,
    showCharacterCount: true,
  },
];

export const fileDescriptionField = [
  {
    label: 'File Description',
    placeholder: 'Add file description',
    name: 'fileDescription',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    minRows: 1,
  },
];
export const finalPageFileInformationFields = [
  {
    label: 'File Name',
    placeholder: 'Add file Name',
    name: 'fileName',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    minRows: 1,
  },
  {
    label: 'File Type',
    placeholder: 'Add file Type',
    name: 'fileType',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    minRows: 1,
  },
];

export const commentsFields = [
  {
    label: 'Additional Comments',
    placeholder: 'Additional Comments',
    name: 'Comment',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    minRows: 3,
    maxLength: 2048,
    showCharacterCount: true,
  },
];
