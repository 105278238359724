import {
  CLEAR_EXECUTIVE_SUMMARY,
  SEARCH_ASSESSMENT_OVERVIEW_FAILURE,
  SEARCH_ASSESSMENT_OVERVIEW_LOADING,
  SEARCH_ASSESSMENT_OVERVIEW_SUCCESS,
  SEARCH_CYBER_RISK_SCORE_FAILURE,
  SEARCH_CYBER_RISK_SCORE_LOADING,
  SEARCH_CYBER_RISK_SCORE_SUCCESS,
  SEARCH_EVIDENCE_REVIEW_FAILURE,
  SEARCH_EVIDENCE_REVIEW_LOADING,
  SEARCH_EVIDENCE_REVIEW_SUCCESS,
  SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE,
  SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING,
  SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS,
  SEARCH_RISK_RATING_OVERVIEW_FAILURE,
  SEARCH_RISK_RATING_OVERVIEW_LOADING,
  SEARCH_RISK_RATING_OVERVIEW_SUCCESS,
  SET_EXPAND_ALL_SECTIONS_SUCCESS,
  SET_LOADING_EXPANDED_SECTIONS,
  SEARCH_RISK_FINDING_SUMMARY_LOADING,
  SEARCH_RISK_FINDING_SUMMARY_SUCCESS,
  SEARCH_RISK_FINDING_SUMMARY_FAILURE,
  SEARCH_VENDOR_COLLABORATION_SCORE_LOADING,
  SEARCH_VENDOR_COLLABORATION_SCORE_SUCCESS,
  SEARCH_VENDOR_COLLABORATION_SCORE_FAILURE,
  SAVE_ES_FEEDBACK_LOADING,
  SAVE_ES_FEEDBACK_SUCCESS,
  SAVE_ES_FEEDBACK_FAILURE,
} from 'actions/executiveSummary';
import { getValidUrl } from 'utils/urlUtils';
import { ADEQUATE, DATA_ACCESS, DATA_STORAGE, YES, YES_VALUE } from 'constants/constants';
import { constructCyberRiskScore } from 'constants/cyberRiskScore';
import { groupBy, isEmpty, isNil } from 'lodash';
import {
  RISK_RATING_SUMMARY_CLIENT_ACCEPTED_STATUS_LIST,
  RISK_RATING_SUMMARY_MET_STATUS_LIST,
  RISK_RATING_SUMMARY_NOT_APPLICABLE_STATUS_LIST,
  RISK_RATING_SUMMARY_OPTIONS,
  RISK_RATING_SUMMARY_UNMET_STATUS_LIST,
  VENDOR_COLLABORATION_KEY_TO_TILE_HEADER_TITLE,
} from 'pages/ExecutiveSummary/constants';
import { getDisplayControlName } from 'utils/stringUtils';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE, SAVE_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';

const initialState = {
  loading: false,
  loadingExpandedSections: false,
  loadingRiskRatingOverview: false,
  loadingAssessmentOverview: false,
  loadingEvidenceReview: false,
  loadingCyberRiskScore: false,
  loadingRiskRatingControlSummary: false,
  loadingRiskFindingSummary: false,
  loadingVendorCollabScore: false,
  riskRatingOverview: {},
  assessmentOverview: {},
  riskRatingControlSummary: {},
  validationEvidence: {},
  cyberRiskScore: [],
  expandAllSections: false,
  riskFindingSummary: {},
  vendorCollaborationScore: {},
};


const addImpactPillsLabel = (data) => {
  let impactPills = [];
  !isEmpty(data) &&
    data?.forEach((item) => {
      if (item) {
        if (item?.includes('PHI') || item?.includes('Protected Health Information')) {
          impactPills?.push('Protected Health Information (PHI)');
        } else if (item?.includes('PII') || item?.includes('Personally Identifiable Information')) {
          impactPills?.push('Personally Identifiable Information (PII)');
        } else if (item?.includes('Employee') && !item?.includes('Employees')) {
          impactPills?.push('Employee Information');
        } else if (item?.includes('PCI') || item?.includes('Payment')) {
          impactPills?.push('Payment Card Industry (PCI)');
        } else if (item?.includes('Internal') || item?.includes('Proprietary') || item?.includes('Confidential') || item?.includes('Sensitive')) {
          impactPills?.push('Internal/Proprietary Information');
        } else if (item?.includes('Customer')) {
          impactPills?.push('Customer Data');
        }
      }
    });
  return impactPills;
};

const getLabelFromRiskSummaryStatus = (status) => {
  if (RISK_RATING_SUMMARY_MET_STATUS_LIST.includes(status)) {
    return RISK_RATING_SUMMARY_OPTIONS.MET;
  }
  if (RISK_RATING_SUMMARY_UNMET_STATUS_LIST.includes(status)) {
    return RISK_RATING_SUMMARY_OPTIONS.UNMET;
  }
  if (RISK_RATING_SUMMARY_CLIENT_ACCEPTED_STATUS_LIST.includes(status)) {
    return RISK_RATING_SUMMARY_OPTIONS.CLIENT_ACCEPTED;
  }
  if (RISK_RATING_SUMMARY_NOT_APPLICABLE_STATUS_LIST.includes(status)) {
    return RISK_RATING_SUMMARY_OPTIONS.NOT_APPLICABLE;
  } 
  
  return '';
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_EXPAND_ALL_SECTIONS_SUCCESS: {
      return {
        ...state,
        expandAllSections: payload,
      };
    }

    case SET_LOADING_EXPANDED_SECTIONS: {
      return {
        ...state,
        loadingExpandedSections: payload,
      };
    }

    case CLEAR_EXECUTIVE_SUMMARY: {
      return {
        ...initialState,
      };
    }

    case SEARCH_RISK_RATING_OVERVIEW_LOADING: {
      return {
        ...state,
        loadingRiskRatingOverview: true,
      };
    }

    case SEARCH_RISK_RATING_OVERVIEW_SUCCESS: {
      let tempRiskRating = payload?.Data;
      if (isNil(tempRiskRating?.length)) {
        tempRiskRating = payload?.Data?.Rows;
      }
      let data = {};
      tempRiskRating &&
        tempRiskRating?.length > 0 &&
        tempRiskRating?.forEach((riskRating) => {
          const likelihoodPills = [];
          if (riskRating.LikelihoodPillsDataAccess_Value === YES) {
            likelihoodPills.push(DATA_ACCESS);
          }
          if (riskRating.LikelihoodPillsDataStorage_Value === YES) {
            likelihoodPills.push(DATA_STORAGE);
          }
          if (riskRating.OffshoreDataAccessId === YES_VALUE) {
            likelihoodPills.push(DATA_ACCESS);
          }
          if (riskRating.OffshoreDataStorageId === YES_VALUE) {
            likelihoodPills.push(DATA_STORAGE);
          }

          const impactPills = riskRating?.ImpactLevelPills ? riskRating?.ImpactLevelPills?.replaceAll('\n', ',').split(',').filter(Boolean) : [];

          const riskRatingList = [
            {
              Id: 1,
              Type: 'Risk Rating',
              Score: riskRating?.RiskRatingScore,
              TileColor: riskRating?.RiskRatingScore,
              Summary: '',
              Pills: [],
              Description: riskRating?.RiskRatingRecommendedAction
                ? `**Summary**</br></br><div style="padding-left:30px;">${riskRating?.RatingSummary.trim()}</div></br>  **Recommended Action**</br></br><div style="padding-left:30px;">${riskRating?.RiskRatingRecommendedAction
                }</div>`
                : '',
              DisabledExpansion: false,
            },
            {
              Id: 2,
              Type: 'Impact Level',
              Score: riskRating?.ImpactLevel,
              TileColor: riskRating?.ImpactLevel,
              Pills: addImpactPillsLabel(impactPills),
              Summary: '',
              Description: '',
              DisabledExpansion: true,
            },
            {
              Id: 3,
              Type: 'Likelihood Level',
              Score: riskRating?.LikelihoodLevel,
              TileColor: riskRating?.LikelihoodLevel,
              Pills: likelihoodPills,
              Summary: '',
              Description: '',
              DisabledExpansion: true,
            },
          ];

          data[riskRating?.EnvironmentId] = riskRatingList;
        });

      return {
        ...state,
        loadingRiskRatingOverview: false,
        riskRatingOverview: data,
      };
    }

    case SEARCH_RISK_RATING_OVERVIEW_FAILURE: {
      return {
        ...state,
        loadingRiskRatingOverview: false,
        riskRatingOverview: [],
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_LOADING: {
      return {
        ...state,
        loadingAssessmentOverview: true,
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_SUCCESS: {
      let tempAssessmentOverview = payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0];
      tempAssessmentOverview.ProductUrl = getValidUrl(tempAssessmentOverview.ProductUrl);
      return {
        ...state,
        loadingAssessmentOverview: false,
        assessmentOverview: tempAssessmentOverview,
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_FAILURE: {
      return {
        ...state,
        loadingAssessmentOverview: false,
        assessmentOverview: {},
      };
    }

    case SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING: {
      return {
        ...state,
        loadingRiskRatingControlSummary: true,
      };
    }

    case SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS: {
      const tempRiskRatingControlSummary = payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows;
      let finalRiskRatingControlSummary = {};

      tempRiskRatingControlSummary &&
        tempRiskRatingControlSummary.forEach((it) => {
          let Tier1DescriptionBullets = '';
          let Tier2DescriptionBullets = '';
          let Tier3DescriptionBullets = '';
          let Tier4DescriptionBullets = '';
          const Tier1InadequaciesList = it?.Tier1InadequaciesList.map((tier) => {
            const inadequacy = `${tier?.TierPillName} (${tier?.InadequaciesIdentified})`;
            Tier1DescriptionBullets = Tier1DescriptionBullets + `<li>${inadequacy}</li>`;
            return inadequacy;
          });

          const Tier2InadequaciesList = it?.Tier2InadequaciesList.map((tier) => {
            const inadequacy = `${tier?.TierPillName} (${tier?.InadequaciesIdentified})`;
            Tier2DescriptionBullets = Tier2DescriptionBullets + `<li>${inadequacy}</li>`;
            return inadequacy;
          });

          const Tier3InadequaciesList = it?.Tier3InadequaciesList.map((tier) => {
            const inadequacy = `${tier?.TierPillName} (${tier?.InadequaciesIdentified})`;
            Tier3DescriptionBullets = Tier3DescriptionBullets + `<li>${inadequacy}</li>`;
            return inadequacy;
          });

          const Tier4InadequaciesList = it?.Tier4InadequaciesList.map((tier) => {
            const inadequacy = `${tier?.TierPillName} (${tier?.InadequaciesIdentified})`;
            Tier4DescriptionBullets = Tier4DescriptionBullets + `<li>${inadequacy}</li>`;
            return inadequacy;
          });

          const tempRiskRatingControlSummaryList = [
            {
              Id: 1,
              Type: `${it?.Tier1Name} Met`,
              Score: `${Math.floor(it?.Tier1Percent)}%`,
              TileColor: 'Tier',
              Summary: `${isNil(it?.Tier1TotalAdequacies) ? 0 : it?.Tier1TotalAdequacies} ${it?.Tier1TotalAdequacies === 1 ? 'Inadequacy' : 'Inadequacies'
                } identified`,
              Definition: '',
              Pills: Tier1InadequaciesList,
              Description: `**${it?.Tier1Name} Results**</br></br>There ${it?.Tier1TotalAdequacies === 1
                ? `was ${isNil(it?.Tier1TotalAdequacies) ? 0 : it?.Tier1TotalAdequacies} inadequacy`
                : `were ${isNil(it?.Tier1TotalAdequacies) ? 0 : it?.Tier1TotalAdequacies} inadequacies`
                } identified in ${it?.Tier1Name
                } controls related to the following:</br></br><div style="padding-left:30px;"><ul>${Tier1DescriptionBullets}</ul></div>`,
            },
            {
              Id: 2,
              Type: `${it?.Tier2Name} Met`,
              Score: `${Math.floor(it?.Tier2Percent)}%`,
              TileColor: 'Tier',
              Summary: `${isNil(it?.Tier2TotalAdequacies) ? 0 : it?.Tier2TotalAdequacies} ${it?.Tier2TotalAdequacies === 1 ? 'Inadequacy' : 'Inadequacies'
                } identified`,
              Definition: '',
              Pills: Tier2InadequaciesList,
              Description: `**${it?.Tier2Name} Results**</br></br>There ${it?.Tier2TotalAdequacies === 1
                ? `was ${isNil(it?.Tier2TotalAdequacies) ? 0 : it?.Tier2TotalAdequacies} inadequacy`
                : `were ${isNil(it?.Tier2TotalAdequacies) ? 0 : it?.Tier2TotalAdequacies} inadequacies`
                } identified in ${it?.Tier2Name
                } controls related to the following:</br></br><div style="padding-left:30px;"><ul>${Tier2DescriptionBullets}</ul></div>`,
            },
            {
              Id: 3,
              Type: `${it?.Tier3Name} Met`,
              Score: `${Math.floor(it?.Tier3Percent)}%`,
              TileColor: 'Tier',
              Summary: `${isNil(it?.Tier3TotalAdequacies) ? 0 : it?.Tier3TotalAdequacies} ${it?.Tier3TotalAdequacies === 1 ? 'Inadequacy' : 'Inadequacies'
                } identified`,
              Definition: '',
              Pills: Tier3InadequaciesList,
              Description: `**${it?.Tier3Name} Results**</br></br>There ${it?.Tier3TotalAdequacies === 1
                ? `was ${isNil(it?.Tier3TotalAdequacies) ? 0 : it?.Tier3TotalAdequacies} inadequacy`
                : `were ${isNil(it?.Tier3TotalAdequacies) ? 0 : it?.Tier3TotalAdequacies} inadequacies`
                } identified in ${it?.Tier3Name
                } controls related to the following:</br></br><div style="padding-left:30px;"><ul>${Tier3DescriptionBullets}</ul></div>`,
            },
            {
              Id: 4,
              Type: `${it?.Tier4Name} Met`,
              Score: `${Math.floor(it?.Tier4Percent)}%`,
              TileColor: 'Tier',
              Summary: `${isNil(it?.Tier4TotalAdequacies) ? 0 : it?.Tier4TotalAdequacies} ${it?.Tier4TotalAdequacies === 1 ? 'Inadequacy' : 'Inadequacies'
                } identified`,
              Definition: '',
              Pills: Tier4InadequaciesList,
              Description: `**${it?.Tier4Name} Results**</br></br>There ${it?.Tier4TotalAdequacies === 1
                ? `was ${isNil(it?.Tier4TotalAdequacies) ? 0 : it?.Tier4TotalAdequacies} inadequacy`
                : `were ${isNil(it?.Tier4TotalAdequacies) ? 0 : it?.Tier4TotalAdequacies} inadequacies`
                } identified in ${it?.Tier4Name
                } controls related to the following:</br></br><div style="padding-left:30px;"><ul>${Tier4DescriptionBullets}</ul></div>`,
            },
          ];

          finalRiskRatingControlSummary = {
            ...finalRiskRatingControlSummary,
            [it.EnvironmentId]: tempRiskRatingControlSummaryList,
          };
        });

      return {
        ...state,
        loadingRiskRatingControlSummary: false,
        riskRatingControlSummary: finalRiskRatingControlSummary,
      };
    }

    case SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE: {
      return {
        ...state,
        loadingRiskRatingControlSummary: false,
        riskRatingControlSummary: [],
      };
    }

    case SEARCH_EVIDENCE_REVIEW_LOADING: {
      return {
        ...state,
        loadingEvidenceReview: true,
      };
    }

    case SEARCH_EVIDENCE_REVIEW_SUCCESS: {
      let finalValidationEvidence = [];
      if (payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows) {
        const filteredValidationEvidence = payload?.Data?.Rows?.filter((it) => !it?.ControlName.includes('Security Implementation Capabilities -'));
        const securityImplCapabilities = payload?.Data?.Rows?.filter((it) => it?.ControlName.includes('Security Implementation Capabilities -')).map(
          (it, index) => {
            return {
              Id: index + 1,
              Requirement: it?.ControlName.replace('Security Implementation Capabilities -', ''),
              Outcome_Value: it?.Outcome_Value,
              QuestionText: it?.QuestionText,
              Response: isNil(it?.Response) || isEmpty(it?.Response) ? ' - ' : it?.Response,
              VendorComment: isNil(it?.VendorComment) || isEmpty(it?.VendorComment) ? ' - ' : it?.VendorComment,
            };
          }
        );

        finalValidationEvidence = filteredValidationEvidence?.map((evidence, index) => {
          return {
            ...evidence,
            IsMet: isNil(evidence?.Outcome_Value) || evidence?.Outcome_Value === 'N/A' || evidence?.Outcome_Value === 'Not Applicable' ? null : evidence?.Outcome_Value?.toLowerCase() === ADEQUATE,
            Id: index + 1,
            DisplayControlName: getDisplayControlName(evidence),
            SecurityImplCapabilities: evidence?.ControlName.includes('Security Implementation Capabilities') ? securityImplCapabilities : [],
          };
        });
      }

      const envWiseValidationReview = groupBy(finalValidationEvidence, (it) => it.EnvironmentId);

      return {
        ...state,
        loadingEvidenceReview: false,
        validationEvidence: envWiseValidationReview,
      };
    }

    case SEARCH_EVIDENCE_REVIEW_FAILURE: {
      return {
        ...state,
        loadingEvidenceReview: false,
        validationEvidence: [],
      };
    }

    case SEARCH_CYBER_RISK_SCORE_LOADING: {
      return {
        ...state,
        loadingCyberRiskScore: true,
      };
    }

    case SEARCH_CYBER_RISK_SCORE_SUCCESS: {
      const tempCyberRiskScore = payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0];
      const tempCyberRiskScoreList = constructCyberRiskScore(tempCyberRiskScore);

      return {
        ...state,
        loadingCyberRiskScore: false,
        cyberRiskScore: tempCyberRiskScoreList,
      };
    }

    case SEARCH_CYBER_RISK_SCORE_FAILURE: {
      return {
        ...state,
        loadingCyberRiskScore: false,
        cyberRiskScore: [],
      };
    }

    case SEARCH_RISK_FINDING_SUMMARY_LOADING: {
      return {
        ...state,
        loadingRiskFindingSummary: true,
      };
    }

    case SEARCH_RISK_FINDING_SUMMARY_SUCCESS: {
      let tempRiskFindingSummary = payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows;
      tempRiskFindingSummary = tempRiskFindingSummary?.map((riskSummary, index) => {
        return {
          ...riskSummary,
          metUnmetLabel: getLabelFromRiskSummaryStatus(riskSummary?.Status),
          Id: index + 1,
          DisplayControlName: getDisplayControlName(riskSummary),
          CompletionDate: !isNil(riskSummary?.CompletionDate) ? convertToLocalDateTime(new Date(riskSummary?.CompletionDate), 'MM/dd/yyyy') : '',
        };
      });
      const envWiseRiskFindingSummary = groupBy(tempRiskFindingSummary, (it) => it.EnvironmentId);
      return {
        ...state,
        loadingRiskFindingSummary: false,
        riskFindingSummary: envWiseRiskFindingSummary,
      };
    }

    case SEARCH_RISK_FINDING_SUMMARY_FAILURE: {
      return {
        ...state,
        loadingRiskFindingSummary: false,
        riskFindingSummary: [],
      };
    }

    case SEARCH_VENDOR_COLLABORATION_SCORE_LOADING: {
      return {
        ...state,
        loadingVendorCollabScore: true,
      };
    }

    case SEARCH_VENDOR_COLLABORATION_SCORE_FAILURE: {
      return {
        ...state,
        loadingVendorCollabScore: false,
        vendorCollaborationScore: [],
      };
    }

    case SEARCH_VENDOR_COLLABORATION_SCORE_SUCCESS: {
      return {
        ...state,
        loadingVendorCollabScore: false,
        vendorCollaborationScore: Object.fromEntries(
          Object.entries(payload?.Data?.Rows[0])
            .filter(([key]) => key in VENDOR_COLLABORATION_KEY_TO_TILE_HEADER_TITLE)
            .sort(
              ([a], [b]) =>
                Object.keys(VENDOR_COLLABORATION_KEY_TO_TILE_HEADER_TITLE).indexOf(a) - Object.keys(VENDOR_COLLABORATION_KEY_TO_TILE_HEADER_TITLE).indexOf(b)
            )
        ),
      };
    }

    case SAVE_ES_FEEDBACK_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SAVE_ES_FEEDBACK_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SAVE_ES_FEEDBACK_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
